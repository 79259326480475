// [!!] SHARED; Nie używać _, jQuery ani żadnych modułów UMD

import {EventsEmitter} from "./events-emitter.js";
import isArray from 'lodash-es/isArray.js';
import isObject from 'lodash-es/isObject.js';
import isFunction from 'lodash-es/isFunction.js';
import find from 'lodash-es/find.js';

export class Store extends EventsEmitter {

    constructor(initialState = {}) {
        super();
        this.previousData = {};
        this.data = initialState;
    }

    /**
     * Ustawia stan.
     * Po ustawieniu stanu powiadamia subskrybentów o zmianie @see on()
     *
     * możliwe parametry w `options`:
     *  - {Boolean }merge - łączy nowy stan z poprzednim jeśli `true` (dom. `false`)
     *
     * @param {String} key      Nazwa stanu
     * @param {*}      data     Nowa reprezentacja stanu
     * @param {Object} options  Dodatkowe prametry
     */
    set(key, data, options = {merge: false}) {

        if (options.merge === true) {
            if (isArray(data)) {
                data = this.mergeStates(this.get(key), data, options);
            } else {
                data = Object.assign({}, this.get(key) || {}, data);
            }
        }

        this.previousData = Object.assign({}, this.data);
        this.data[key] = data;
        this.updatedAt = Date.now();

        // Powiadamia o change:state:key
        if (isObject(this.data[key]) && !isArray(this.data[key])) {
            for (let field of Object.keys(this.data[key])) {
                if (this.data[key][field] !== (this.previousData[key] || {})[field]) {
                    this.trigger(`change:${key}:${field}`, this.data[key][field], (this.previousData[key] || {})[field], options);
                }
            }
        }
        this.trigger(`change:${key}`, data, this.previousData[key], options);
        this.trigger('change', this.data, this.previousData, options);
    }

    /**
     * Łączy nowy stan ze starym @see set()
     * @param {String }      key
     * @param {Object|Array} data
     * @param {Object}       options
     */
    merge(key, data, options = {}) {
        this.set(key, data, Object.assign({}, options, { merge: true }));
    }

    /**
     * Zwraca stan
     * @param {String} key
     * @param {*}      defaultValue
     * @returns {*}
     */
    get(key, defaultValue = null) {
        return key ? (this.data[key] || defaultValue) : this.data;
    }

    /**
     * Łączy dwa stany, które są tablicami.
     * Zwraca nową tablicę
     *
     * możliwe parametry w `options`:
     *  - {Boolean} remove     - Usuwa obiekty niewystępujące w nowym stanie (dom. `false`)
     *  - {String}  primaryKey - klucz podstawowy / id obiektów w tablicy (dom. `id`)
     *
     * @param {Array}  state    Stary stan do złączenia
     * @param {Array}  data     Nowy stan do złącznia
     * @param {Object} options  Dodatkowe parametry
     * @returns {Array}
     */
    mergeStates(state, data, options = {}) {
        const pk = options.primaryKey || 'id';
        const remove = Boolean(options.remove);

        // Łączy stany
        let nextState = [...(state || [])];
        let model;
        for (let row of data) {

            if (isFunction(pk)) {
                model = find(state, m => pk(m, row));
            } else {
                model = find(state, { [pk]: row[pk] });
            }

            if (model) {
                nextState[state.indexOf(model)] = Object.assign({}, model, row);
            } else {
                nextState.push(row);
            }
        }

        // Usuwa obiekty niewystępujące w nowym stanie
        if (remove) {
            const dataPks = data.map(row => row[pk]);
            nextState = nextState.filter(row => includes(dataPks, row[pk]));
        }

        return nextState;
    }
}
