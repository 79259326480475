let lastId = 0;

const createElement = (tagName, props = {}) => {
    const el = document.createElement(tagName);
    for (let prop in props) {
        el[prop] = props[prop];
    }
    return el;
};

export class DialogView {

    constructor(props = {}) {
        this.cid = `modal-${++lastId}`;
        this.props = props;
        this.handlers = {
            close: [],
            open: [],
        };
    }

    open() {
        if (this.$modal) {
            return this;
        }

        this.$parent = document.body;
        this.$modal = createElement('div', {className: 'pk-modal', id: this.cid});
        if (this.props.fullScreen) {
            this.$modal.classList.add('pk-modal-full-screen');
        }

        this.$dialog = createElement('div', {className: 'pk-modal-dialog'});
        this.$content = createElement('div', {className: 'pk-modal-content'});
        this.$body = createElement('div', {className: 'pk-modal-body'});
        this.$closeButton = createElement('button', {
            type: 'button',
            className: 'pk-modal-close pk-modal-floating-close pk-btn',
            innerHTML: '&times;',
        });

        this.$modal.appendChild(this.$dialog);
        this.$dialog.appendChild(this.$content);
        this.$content.appendChild(this.$closeButton);
        this.$content.appendChild(this.$body);
        this.$parent.appendChild(this.$modal);

        this.$modal.style.display = 'block';
        this.$closeButton.addEventListener('click', () => this.close());

        for (let cb of this.handlers.open) {
            cb(this);
        }
        return this;
    }

    close() {
        if (!this.$modal) {
            return this;
        }
        this.$parent.removeChild(this.$modal);
        // this.$modal.remove();
        for (let cb of this.handlers.close) {
            cb(this);
        }
        return this;
    }

    showContent(content) {
        this.open();
        this.$body.innerHTML = content;
        return this;
    }

    showView(view) {
        if (this.view) {
            this.view.destroy();
        }
        this.view = view;
        this.open();
        this.view.setElement(this.$body);
        this.view.render();
        this.onClose(() => {
            view.destroy();
        });
        return this;
    }

    onClose(cb) {
        this.handlers.close.push(cb);
        return this;
    }

    onOpen(cb) {
        this.handlers.open.push(cb);
        return this;
    }

}
