// [!!] SHARED; Nie używać _, jQuery ani żadnych modułów UMD

let lastId = 0;
const getId = () => `l${++lastId}`;

export class EventsEmitter {

    constructor() {
        this._eventListenerId = getId();
        this.listeners = {};
    }

    /**
     * Dodaje obiekt, który będzie informowany o eventach
     * @param {Object} object
     * @param {Object} handlers
     * @returns {EventsEmitter}
     */
    addEventListener(object, handlers) {
        if (!object._eventListenerId) {
            object._eventListenerId = getId();
        }

        let listener = this.listeners[object._eventListenerId];
        if (!listener) {
            listener = this.listeners[object._eventListenerId] = {
                id: object._eventListenerId,
                handlers: [],
            };
        }

        for (let key in handlers) {
            if (handlers.hasOwnProperty(key)) {
                listener.handlers.push({
                    key,
                    id: getId(),
                    handler: handlers[key],
                });
            }
        }
        return this;
    }


    /**
     * Usuwa informaowanie obiektu o eventach
     * @param {Object} object
     * @returns {EventsEmitter}
     */
    removeEventListener(object) {
        if (!object._eventListenerId) {
            return this;
        }
        if (this.listeners[object._eventListenerId]) {
            delete this.listeners[object._eventListenerId];
        }
        return this;
    }

    /**
     * Dodaje nasłuch zdarzenia
     * @param {string} event
     * @param {function} handler
     * @return {EventsEmitter}
     */
    on(event, handler) {
        return this.addEventListener(this, {
            [event]: handler,
        });
    }

    /**
     * Usuwa nasłuch zdarzenia
     * @param event
     * @param handler
     * @return {EventsEmitter}
     */
    off (event, handler) {
        if (event === void 0) {
            this.listeners = {};
            return this;
        }
        const listener = this.listeners[this._eventListenerId];
        if (!listener) {
            return this;
        }
        for (let i = 0; i < listener.handlers.length; i++) {
            if (listener.handlers[i].key === event && (!handler || listener.handlers[i].handler === handler)) {
                listener.handlers.splice(i--, 1);
            }
        }
        return this;
    }

    /**
     * Powiadamia subskrybentów o evencie
     * @param {String} key
     * @param {Object} data
     * @param {*} args
     */
    trigger(key, data = {}, ...args) {
        let handler;
        for (let listenerId in this.listeners) {
            if (this.listeners.hasOwnProperty(listenerId)) {
                for (handler of this.listeners[listenerId].handlers) {
                    if (handler.key === key || handler.key === '*') {
                        handler.handler(data, ...args);
                    }
                }
            }
        }
    }

    /**
     * Powiadamia subskrybentów o evencie
     * @alias trigger
     * @param event
     * @param data
     */
    emit(event, ...data) {
        this.trigger(event, ...data);
    }

}

export const triggerStateChange = (trigger, currentState, previousState, root, depth) => {
    let eventName;
    for (let key in currentState) {
        if (currentState.hasOwnProperty(key) && currentState[key] !== previousState[key]) {
            eventName = (root ? root : 'change') + `:${key}`;
            trigger(eventName, currentState[key], previousState[key], currentState, previousState);
            if (typeof currentState[key] === 'object' && !Array.isArray(currentState[key]) && depth < 20) {
                triggerStateChange(trigger, currentState[key], previousState[key] || {}, eventName, depth + 1);
            }
        }
    }
};

export const triggerStateChangeEvents = (events, currentState, previousState, root, depth) => {
    triggerStateChange(
        events.trigger.bind(events),
        currentState,
        previousState,
        root,
        depth
    );
};

