// [!!] SHARED; Nie używać _, jQuery ani żadnych modułów UMD

import {GET, restRequest} from "../../admin/script/util/http.js";
import {param} from "../../admin/script/util/param.js";


export class AbstractProvider {

    constructor(props) {
        this.name = props.name;
        this.url = props.url;
        this.method = props.method || GET;
        this.fetchParams = props.fetchParams || {};
        this.storeProvider = props.store;

        if (!this.name) {
            throw 'props.name is required';
        }
        if (!this.storeProvider) {
            throw 'props.store is required';
        }
    }

    setData(data) {
        this.storeProvider.merge(this.name, data);
        return this;
    }

    setState(nextState) {
        this.storeProvider.merge(`${this.name}Meta`, nextState);
        return this;
    }

    getState() {
        return this.storeProvider.get(`${this.name}Meta`, {});
    }

    getAll() {
        return this.storeProvider.get(this.name, []);
    }

    isRequested() {
        return this.getState().isRequested;
    }

    isFetched() {
        return this.getState().fetchedAt !== void 0;
    }

    getByPk(pk) {
        for (let model of this.getAll()) {
            if (model.id === pk) {
                return model;
            }
        }
        return null;
    }

    prepareUrl(query = {}) {
        let url = this.url
        const params = param({...this.fetchParams, ...query});
        if (params) {
            url += (url.indexOf('?') === -1 ? '?' : '&') + params;
        }
        return url;
    }

    getHeaders() {
        return {};
    }

    getAjaxOptions() {
        return {};
    }

    getResponseData(response) {
        return response;
    }

    async fetch(query = {}, postData = {}) {
        this.setState({isRequested: true});
        try {
            const options = this.getAjaxOptions();
            const response = await restRequest(this.method, this.prepareUrl(query), postData, {
                credentials: 'include',
                referrerPolicy: 'origin',
                ...options,
                headers: {
                    ...(options.headers || {}),
                    ...this.getHeaders(),
                },
            })
            this.setData(this.getResponseData(response));
            this.setState({
                isRequested: false,
                fetchedAt: Date.now(),
            });
            return response;
        } catch (e) {
            console.error(e);
            this.setState({isRequested: false});
        }
    }

    async fetchAll() {
        if (!this.url) {
            return;
        }
        return this.fetch();
    }

}
