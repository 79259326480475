import {AbstractView, LitRenderer} from "../../../../admin/script/views/abstract-view.js";
import {Marker, Map, TileLayer} from "leaflet";
import {MarkerClusterGroup} from "leaflet.markercluster/src";
import debounce from 'lodash-es/debounce.js';
import throttle from 'lodash-es/throttle.js';
import includes from 'lodash-es/includes.js';
import filter from 'lodash-es/filter.js';
import without from 'lodash-es/without.js';
import sortBy from 'lodash-es/sortBy.js';
import {attribution, mapPath, prepareUrl} from '../config.js';
import {NAMES as providersNames, AVAILABLE_PROVIDERS, pointIdFormatter, PROVIDER_COMPATIBILITY} from '../providers.js';
import {getPointMarkerIcon, searchCenterMarker} from './map-marker.js';
import template from './map-view-template.js';
import markerPopupTemplate from './map-marker-popup-template.js';
import {providerAdditionalFunctions} from "../provider-additional-functions.js";

import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import '../css/points-map.scss';
import '../css/markers.scss';
import '../css/pk-utils.scss';

const STATIC_FUNCTIONS = ['cod', 'collect', 'send'];

export class MapView extends AbstractView {

    get state() {
        return this.store.get('pointsMap', {});
    }

    set state(nextState) {
        this.store.merge('pointsMap', nextState);
    }

    constructor(props) {
        super(props);
        this.template = template;
        this.pointsProvider = props.pointsProvider;
        this.pointsMarkers = {};
        this.performSearchDebounced = throttle(() => this.performSearch(), 1000);
        this.updateViewDebounced = throttle(() => this.rerender(), 250);
        this.fetchPointsDebounced = debounce(() => this.fetchPoints(), 1000);
        this.updateMarkersDebounced = debounce(() => this.updateMarkers(), 500);
    }

    getDefaultRenderer() {
        return new LitRenderer();
    }

    storeEvents() {
        const updateView = () => this.updateViewDebounced();

        const updateFunctions = () => {
            // const availableFunctions = this.getAvailableAdditionalFunctions().map(fn => fn.id);
            const availableFunctions = [
                ...STATIC_FUNCTIONS,
                ...this.getAvailableAdditionalFunctions().map(fn => fn.id),
            ];
            this.state = {functions: this.state.functions.filter(fn => availableFunctions.indexOf(fn) !== -1)};
        };

        const updatePoints = () => {
            this.updateMarkersDebounced();
            if (this.state.searchQuery) {
                this.performSearchDebounced();
            }
            this.fetchPointsDebounced();
        };

        return {
            'change:points': () => {
                this.updateMarkersDebounced();
                updateView();
            },
            'change:pointsMap:functions': () => {
                updatePoints();
                updateView();
            },
            'change:pointsMap:searchQuery': updateView,
            'change:pointsMap:currentPoint': updateView,
            'change:pointsMap:isSearchRequested': updateView,
            'change:pointsMap:availableProviders': availableProviders => {
                this.state = {selectedProviders: (this.state.selectedProviders || []).filter(m => includes(availableProviders, m))};
                updateFunctions();
                updateView();
            },
            'change:pointsMap:selectedProviders': () => {
                updateFunctions();
                updatePoints();
                updateView();
            },
        };
    }

    setCurrentPoint(point, focus = true) {
        this.state = {currentPoint: point};
        if (focus) {
            this.map.setView([point.latitude, point.longitude], Math.max(16, this.map.getZoom()));
            if (this.pointsMarkers[point.id]) {
                this.pointsMarkers[point.id].openPopup();
            }
        }
    }

    getVisiblePoints() {
        const points = this.pointsProvider.getAll();
        const visiblePoints = [];
        if (this.map) {
            const bounds = this.map.getBounds();
            for (let point of points) {
                if (
                    this.doPointFunctionsFilter(point) &&
                    includes(this.state.selectedProviders, point.provider) &&
                    bounds.contains([point.latitude, point.longitude])
                ) {
                    visiblePoints.push(point);
                }
            }
        }
        return visiblePoints;
    }

    getAvailableProviders() {
        return filter(this.state.availableProviders, id => includes(AVAILABLE_PROVIDERS, id)).map(id => ({
            id,
            name: providersNames[id] || id,
        }));
    }

    toggleSelectedProvider(id) {
        if (includes(this.state.selectedProviders, id)) {
            this.state = {selectedProviders: without(this.state.selectedProviders, id)};
        } else {
            this.state = {selectedProviders: [...this.state.selectedProviders, id]};
        }
    }

    templateContext() {
        return {
            ...this.state,
            viewId: this.cid,
            availableCouriers: this.getAvailableProviders(),
            additionalFunctions: this.getAvailableAdditionalFunctions(),
            visiblePoints: sortBy(this.getVisiblePoints(), point => (point.street + point.city).toLowerCase()),
            toggleSelectedProvider: this.toggleSelectedProvider.bind(this),
            setState: props => {
                this.state = props
            },
            performSearchDebounced: this.performSearchDebounced.bind(this),
            performSearch: e => {
                e.preventDefault();
                this.performSearchDebounced();
            },
            selectPoint: pointId => {
                const point = this.pointsProvider.getByPk(pointId);
                if (point) {
                    this.setCurrentPoint(point, true);
                }
            },
            confirmPoint: pointId => {
                const point = this.pointsProvider.getByPk(pointId);
                if (point) {
                    this.setCurrentPoint(point, false);
                    this.events.trigger('confirm:point', point)
                }
            },
            onInputSearch: e => {
                this.events.trigger('input:searchQuery', e);
                if (!e.defaultPrevented) {
                    this.state = {searchQuery: e.target.value};
                }
            },
            toggleFunction: this.toggleFunction.bind(this),
        };
    }

    getAvailableAdditionalFunctions(providers = null, ignoreDependencies = false) {
        const selectedFunctions = this.state.functions;
        const additionalFunctions = [];
        let additionalFunction;
        let dependency;

        const checkDependencies = (dependencies) => {
            if (!ignoreDependencies && Array.isArray(dependencies) && dependencies.length > 0) {
                for (dependency of dependencies) {
                    if (selectedFunctions.indexOf(dependency) === -1) {
                        return false;
                    }
                }
            }
            return true;
        };

        for (let provider of (providers || this.state.selectedProviders)) {
            if (providerAdditionalFunctions[provider]) {
                for (additionalFunction of providerAdditionalFunctions[provider]) {
                    if (checkDependencies(additionalFunction.dependsOn)) {
                        additionalFunctions.push(additionalFunction);
                    }
                }
            }
        }
        return additionalFunctions;
    }

    toggleFunction(functionName, set) {
        const state = this.state;
        if (set) {
            if (state.functions.indexOf(functionName) === -1) {
                this.state = {
                    functions: [
                        ...state.functions,
                        functionName,
                    ],
                };
            }
        } else {
            this.state = {
                functions: state.functions.filter(fn => fn !== functionName),
            };
        }
    }

    render() {
        super.render();
        this.createMap();
    }

    createMap() {
        if (this.map) {
            return;
        }

        this.mapContainerEl = this.mapContainerEl || this.$el.querySelector('.pk-map-container');
        if (!this.mapContainerEl) {
            return;
        }

        this.map = new Map(this.mapContainerEl, {
            minZoom: 7,
            maxZoom: 18,
            center: [this.state.latitude, this.state.longitude],
            zoom: this.state.zoom,
        });

        this.map.on('move zoom', () => {
            this.fetchPointsDebounced();
            this.updateMarkersDebounced();
        });

        this.markersLayers = {};
        for (let provider of AVAILABLE_PROVIDERS) {
            this.markersLayers[provider] = new MarkerClusterGroup({
                removeOutsideVisibleBounds: true,
                disableClusteringAtZoom: 14,
                spiderfyOnMaxZoom: false,
                animate: true,
            });
            this.map.addLayer(this.markersLayers[provider]);
        }

        new TileLayer(prepareUrl(mapPath, {
            baseUrl: this.props.baseUrl,
            token: this.props.token,
        }), {
            attribution,
        }).addTo(this.map);

        if (this.state.searchQuery) {
            this.performSearchDebounced().then(data => {
                if (data && data.length === 0) {
                    this.fetchPoints();
                }
            });
        } else {
            this.fetchPoints();
        }
    }

    fetchPoints() {
        if (this.map) {
            const bounds = this.map.getBounds();
            const query = {
                providers: this.state.selectedProviders,
                functions: this.state.functions,
            };

            if (this.map.getZoom() < 12) {
                const pos = this.map.getCenter();
                query.latitude = pos.lat;
                query.longitude = pos.lng;
                query.radius = 20;
            } else {
                query.bounds = [
                    bounds.getNorth(),
                    bounds.getEast(),
                    bounds.getSouth(),
                    bounds.getWest()
                ].join(',')
            }

            this.pointsProvider.fetch(query);
        }
    }

    updateMarkers() {
        if (this.map) {
            const points = this.pointsProvider.getAll();
            const bounds = this.map.getBounds();
            for (let point of points) {
                if (!this.doPointFunctionsFilter(point)) {
                    this.removePointMarker(point);
                } else if (bounds.contains([point.latitude, point.longitude])) {
                    this.addPointMarker(point);
                }
            }
        }

        const validProviders = this.getSelectedProvidersWithCompatible();
        for (let layer in this.markersLayers) {
            if (this.markersLayers.hasOwnProperty(layer) && includes(validProviders, layer)) {
                this.map.addLayer(this.markersLayers[layer]);
            } else {
                this.markersLayers[layer].remove();
            }
        }
    }

    getSelectedProvidersWithCompatible() {
        const validProviders = [];
        for (let provider of this.state.selectedProviders) {
            validProviders.push(provider);
            if (PROVIDER_COMPATIBILITY[provider]) {
                for (let compatibleProvider of PROVIDER_COMPATIBILITY[provider]) {
                    if (!validProviders.includes(compatibleProvider)) {
                        validProviders.push(compatibleProvider);
                    }
                }
            }
        }
        return validProviders;
    }

    createMarker(point) {
        const address = `${point.street}, ${point.city} ${point.zip}`;
        const marker = new Marker([point.latitude, point.longitude], {
            title: `[${pointIdFormatter(point)}] ${address}`,
            icon: getPointMarkerIcon(point),
            riseOnHover: true,
        });

        const templateContext = this.templateContext();
        marker.bindPopup(() => {
            const markerElement = document.createElement('div');
            this.htmlRenderer.attachHtml(markerElement, markerPopupTemplate({
                ...templateContext,
                additionalFunctions: this.getAvailableAdditionalFunctions([point.provider], true),
                state: this.state,
                point,
            }));
            return markerElement;
        });
        marker.on('click', () => {
            this.setCurrentPoint(point, false);
        });

        return marker;
    }

    addPointMarker(point) {
        const marker = this.createMarker(point);
        const id = `${point.provider}:${point.id}`;
        if (!this.pointsMarkers[id]) {
            this.pointsMarkers[id] = marker;
            this.markersLayers[point.provider].addLayer(marker);
        }
        return this;
    }

    removePointMarker(point) {
        const id = `${point.provider}:${point.id}`;
        if (this.pointsMarkers[id]) {
            this.markersLayers[point.provider].removeLayer(this.pointsMarkers[id]);
            this.pointsMarkers[id] = null;
        }
        return this;
    }

    showSearchCenter(latitude, longitude) {
        if (this.searchCenterMarker) {
            this.searchCenterMarker.remove();
        }
        if (latitude && longitude) {
            this.searchCenterMarker = new Marker([latitude, longitude], {
                icon: searchCenterMarker,
            });
            this.searchCenterMarker.addTo(this.map);
            this.map.setView([latitude, longitude], 14);
        }
    }

    createClusters(points, from = 'zip') {
        const clusters = {};
        for (let point of points) {
            if (!clusters[point[from]]) {
                clusters[point[from]] = [];
            }
            clusters[point[from]].push(point);
        }
        return clusters;
    }

    findClusterBoundaries(points) {
        //     NE   latN+   NW
        //       +---------+
        //       |         |
        // lonE- |    X    | lonW+
        //       |         |
        //       +---------+
        //     SE   latS-   SW
        //
        // [[N, E], [S, W]]
        const bounds = [[0, 0], [0, 0]];
        for (let point of points) {
            // N
            if (!bounds[0][0] || point.latitude < bounds[0][0]) {
                bounds[0][0] = point.latitude;
            }

            // E
            if (!bounds[0][1] || point.longitude < bounds[0][1]) {
                bounds[0][1] = point.longitude;
            }

            // S
            if (!bounds[1][0] || point.latitude > bounds[1][0]) {
                bounds[1][0] = point.latitude;
            }

            // W
            if (!bounds[1][1] || point.longitude > bounds[1][1]) {
                bounds[1][1] = point.longitude;
            }
        }
        return bounds;
    }

    centerOnClusterBoundaries(points) {
        const bounds = this.findClusterBoundaries(points);
        this.map.fitBounds(bounds);
    }

    getBiggestCluster(clusters) {
        let current = [];
        for (let clusterId in clusters) {
            if (clusters.hasOwnProperty(clusterId) && clusters[clusterId].length > current.length) {
                current = clusters[clusterId];
            }
        }
        return current;
    }

    async performSearch() {
        this.state = {
            isSearchRequested: true,
            searchResultsError: '',
        };

        let response = null;
        try {
            response = await this.pointsProvider.fetch({
                searchQuery: this.state.searchQuery,
                providers: this.state.selectedProviders,
                functions: this.state.functions,
            });

            if (!this.map) {
                return;
            }

            const points = response.points || [];
            const data = response || {};

            if (data.center && data.center[0] && data.center[1]) {
                this.showSearchCenter(data.center[0], data.center[1]);
            } else if (points.length === 1) {
                this.addPointMarker(points[0]);
                this.setCurrentPoint(points[0], true);
            } else if (points.length > 1) {
                const clusters = {
                    ...this.createClusters(points, 'zip'),
                    ...this.createClusters(points, 'city'),
                };
                const pointsToCenter = this.getBiggestCluster(clusters);
                this.centerOnClusterBoundaries(pointsToCenter);
            } else if (points.length === 0) {
                this.state = {searchResultsError: `Brak punktów spełniających kryteria: ${this.getSearchCriteriaString()}`};
            }
        } catch (e) {
            console.error(e);
        }
        this.state = {isSearchRequested: false};
        return response;
    }

    getSearchCriteriaString() {
        const functions =this.state.functions;
        const criteria = [];
        if (includes(functions, 'cod')) {
            criteria.push('pobranie');
        }
        if (includes(functions, 'collect')) {
            criteria.push('odbiór przesyłki');
        }
        if (includes(functions, 'send')) {
            criteria.push('nadanie przesyłki');
        }
        if (this.state.searchQuery) {
            criteria.push(`fraza ${this.state.searchQuery}`);
        }
        return criteria.join(', ');
    }

    doPointFunctionsFilter(point) {
        for (let fn of this.state.functions) {
            if (Array.isArray(point.functions) && point.functions.indexOf(fn) === -1) {
                return false;
            }
        }
        return true;
    }

    destroy() {
        super.destroy();
        if (this.map) {
            this.map.remove();
            this.map = null;
            this.markersLayers = {};
            this.markersLayers = {};
        }
    }

}
