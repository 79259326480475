import {AbstractProvider} from "../../../providers/abstract-provider.js";
import {pointsDataUrl, prepareUrl} from '../config.js';

export class PointsProvider extends AbstractProvider {
    constructor(props) {
        super({
            url: props.url || prepareUrl(pointsDataUrl, {baseUrl: props.baseUrl}),
            store: props.store,
            name: 'points',
        });
        this.token = props.token;
    }

    getHeaders() {
        return {
            Authorization: `Bearer ${this.token}`,
        };
    }

    getAjaxOptions() {
        return {
            xhrFields: {
                withCredentials: true
            }
        }
    }

    getResponseData(response) {
        return response.points || [];
    }
}
