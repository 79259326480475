export default {
    GS: [-54.283333, -36.500000],
    TF: [-49.35, 70.216667],
    PS: [31.766666666666666, 35.233333],
    AX: [60.116667, 19.900000],
    NR: [-0.5477, 166.920867],
    MF: [18.0731, -63.082200],
    TK: [-9.166667, -171.833333],
    EH: [27.153611, -13.203333],
    AF: [34.516666666666666, 69.183333],
    AL: [41.31666666666667, 19.816667],
    DZ: [36.75, 3.050000],
    AS: [-14.266666666666667, -170.700000],
    AD: [42.5, 1.516667],
    AO: [-8.833333333333334, 13.216667],
    AI: [18.216666666666665, -63.050000],
    AG: [17.116666666666667, -61.850000],
    AR: [-34.583333333333336, -58.666667],
    AM: [40.166666666666664, 44.500000],
    AW: [12.516666666666667, -70.033333],
    AU: [-35.266666666666666, 149.133333],
    AT: [48.2, 16.366667],
    AZ: [40.38333333333333, 49.866667],
    BS: [25.083333333333332, -77.350000],
    BH: [26.233333333333334, 50.566667],
    BD: [23.716666666666665, 90.400000],
    BB: [13.1, -59.616667],
    BY: [53.9, 27.566667],
    BE: [50.833333333333336, 4.333333],
    BZ: [17.25, -88.766667],
    BJ: [6.483333333333333, 2.616667],
    BM: [32.28333333333333, -64.783333],
    BT: [27.466666666666665, 89.633333],
    BO: [-16.5, -68.150000],
    BA: [43.86666666666667, 18.416667],
    BW: [-24.633333333333333, 25.900000],
    BR: [-15.783333333333333, -47.916667],
    VG: [18.416666666666668, -64.616667],
    BN: [4.883333333333333, 114.933333],
    BG: [42.68333333333333, 23.316667],
    BF: [12.366666666666667, -1.516667],
    MM: [16.8, 96.150000],
    BI: [-3.3666666666666667, 29.350000],
    KH: [11.55, 104.916667],
    CM: [3.8666666666666667, 11.516667],
    CA: [45.416666666666664, -75.700000],
    CV: [14.916666666666666, -23.516667],
    KY: [19.3, -81.383333],
    CF: [4.366666666666666, 18.583333],
    TD: [12.1, 15.033333],
    CL: [-33.45, -70.666667],
    CN: [39.916666666666664, 116.383333],
    CX: [-10.416666666666666, 105.716667],
    CC: [-12.166666666666666, 96.833333],
    CO: [4.6, -74.083333],
    KM: [-11.7, 43.233333],
    CD: [-4.316666666666666, 15.300000],
    CG: [-4.25, 15.283333],
    CK: [-21.2, -159.766667],
    CR: [9.933333333333334, -84.083333],
    CI: [6.816666666666666, -5.266667],
    HR: [45.8, 16.000000],
    CU: [23.116666666666667, -82.350000],
    CW: [12.1, -68.916667],
    CY: [35.166666666666664, 33.366667],
    CZ: [50.083333333333336, 14.466667],
    DK: [55.666666666666664, 12.583333],
    DJ: [11.583333333333334, 43.150000],
    DM: [15.3, -61.400000],
    DO: [18.466666666666665, -69.900000],
    EC: [-0.21666666666666667, -78.500000],
    EG: [30.05, 31.250000],
    SV: [13.7, -89.200000],
    GQ: [3.75, 8.783333],
    ER: [15.333333333333334, 38.933333],
    EE: [59.43333333333333, 24.716667],
    ET: [9.033333333333333, 38.700000],
    FK: [-51.7, -57.850000],
    FO: [62, -6.766667],
    FJ: [-18.133333333333333, 178.416667],
    FI: [60.166666666666664, 24.933333],
    FR: [48.86666666666667, 2.333333],
    PF: [-17.533333333333335, -149.566667],
    GA: [0.38333333333333336, 9.450000],
    GM: [13.45, -16.566667],
    GE: [41.68333333333333, 44.833333],
    DE: [52.516666666666666, 13.400000],
    GH: [5.55, -0.216667],
    GI: [36.13333333333333, -5.350000],
    GR: [37.983333333333334, 23.733333],
    GL: [64.18333333333334, -51.750000],
    GD: [12.05, -61.750000],
    GU: [13.466666666666667, 144.733333],
    GT: [14.616666666666667, -90.516667],
    GG: [49.45, -2.533333],
    GN: [9.5, -13.700000],
    GW: [11.85, -15.583333],
    GY: [6.8, -58.150000],
    HT: [18.533333333333335, -72.333333],
    VA: [41.9, 12.450000],
    HN: [14.1, -87.216667],
    HU: [47.5, 19.083333],
    IS: [64.15, -21.950000],
    IN: [28.6, 77.200000],
    ID: [-6.166666666666667, 106.816667],
    IR: [35.7, 51.416667],
    IQ: [33.333333333333336, 44.400000],
    IE: [53.31666666666667, -6.233333],
    IM: [54.15, -4.483333],
    IL: [31.766666666666666, 35.233333],
    IT: [41.9, 12.483333],
    JM: [18, -76.800000],
    JP: [35.68333333333333, 139.750000],
    JE: [49.18333333333333, -2.100000],
    JO: [31.95, 35.933333],
    KZ: [51.166666666666664, 71.416667],
    KE: [-1.2833333333333332, 36.816667],
    KI: [-0.8833333333333333, 169.533333],
    KP: [39.016666666666666, 125.750000],
    KR: [37.55, 126.983333],
    KO: [42.666666666666664, 21.166667],
    KW: [29.366666666666667, 47.966667],
    KG: [42.86666666666667, 74.600000],
    LA: [17.966666666666665, 102.600000],
    LV: [56.95, 24.100000],
    LB: [33.86666666666667, 35.500000],
    LS: [-29.316666666666666, 27.483333],
    LR: [6.3, -10.800000],
    LY: [32.88333333333333, 13.166667],
    LI: [47.13333333333333, 9.516667],
    LT: [54.68333333333333, 25.316667],
    LU: [49.6, 6.116667],
    MK: [42, 21.433333],
    MG: [-18.916666666666668, 47.516667],
    MW: [-13.966666666666667, 33.783333],
    MY: [3.1666666666666665, 101.700000],
    MV: [4.166666666666667, 73.500000],
    ML: [12.65, -8.000000],
    MT: [35.88333333333333, 14.500000],
    MH: [7.1, 171.383333],
    MR: [18.066666666666666, -15.966667],
    MU: [-20.15, 57.483333],
    MX: [19.433333333333334, -99.133333],
    FM: [6.916666666666667, 158.150000],
    MD: [47, 28.850000],
    MC: [43.733333333333334, 7.416667],
    MN: [47.916666666666664, 106.916667],
    ME: [42.43333333333333, 19.266667],
    MS: [16.7, -62.216667],
    MA: [34.016666666666666, -6.816667],
    MZ: [-25.95, 32.583333],
    NA: [-22.566666666666666, 17.083333],
    NP: [27.716666666666665, 85.316667],
    NL: [52.35, 4.916667],
    NC: [-22.266666666666666, 166.450000],
    NZ: [-41.3, 174.783333],
    NI: [12.133333333333333, -86.250000],
    NE: [13.516666666666667, 2.116667],
    NG: [9.083333333333334, 7.533333],
    NU: [-19.016666666666666, -169.916667],
    NF: [-29.05, 167.966667],
    MP: [15.2, 145.750000],
    NO: [59.916666666666664, 10.750000],
    OM: [23.616666666666667, 58.583333],
    PK: [33.68333333333333, 73.050000],
    PW: [7.483333333333333, 134.633333],
    PA: [8.966666666666667, -79.533333],
    PG: [-9.45, 147.183333],
    PY: [-25.266666666666666, -57.666667],
    PE: [-12.05, -77.050000],
    PH: [14.6, 120.966667],
    PN: [-25.066666666666666, -130.083333],
    PL: [52.2252606, 20.9986249],
    PT: [38.71666666666667, -9.133333],
    PR: [18.466666666666665, -66.116667],
    QA: [25.283333333333335, 51.533333],
    RO: [44.43333333333333, 26.100000],
    RU: [55.75, 37.600000],
    RW: [-1.95, 30.050000],
    BL: [17.883333333333333, -62.850000],
    SH: [-15.933333333333334, -5.716667],
    KN: [17.3, -62.716667],
    LC: [14, -61.000000],
    PM: [46.766666666666666, -56.183333],
    VC: [13.133333333333333, -61.216667],
    WS: [-13.816666666666666, -171.766667],
    SM: [43.93333333333333, 12.416667],
    ST: [0.3333333333333333, 6.733333],
    SA: [24.65, 46.700000],
    SN: [14.733333333333333, -17.633333],
    RS: [44.833333333333336, 20.500000],
    SC: [-4.616666666666667, 55.450000],
    SL: [8.483333333333333, -13.233333],
    SG: [1.2833333333333332, 103.850000],
    SX: [18.016666666666666, -63.033333],
    SK: [48.15, 17.116667],
    SI: [46.05, 14.516667],
    SB: [-9.433333333333334, 159.950000],
    SO: [2.066666666666667, 45.333333],
    ZA: [-25.7, 28.216667],
    SS: [4.85, 31.616667],
    ES: [40.4, -3.683333],
    LK: [6.916666666666667, 79.833333],
    SD: [15.6, 32.533333],
    SR: [5.833333333333333, -55.166667],
    SJ: [78.21666666666667, 15.633333],
    SZ: [-26.316666666666666, 31.133333],
    SE: [59.333333333333336, 18.050000],
    CH: [46.916666666666664, 7.466667],
    SY: [33.5, 36.300000],
    TW: [25.033333333333335, 121.516667],
    TJ: [38.55, 68.766667],
    TZ: [-6.8, 39.283333],
    TH: [13.75, 100.516667],
    TL: [-8.583333333333334, 125.600000],
    TG: [6.116666666666666, 1.216667],
    TO: [-21.133333333333333, -175.200000],
    TT: [10.65, -61.516667],
    TN: [36.8, 10.183333],
    TR: [39.93333333333333, 32.866667],
    TM: [37.95, 58.383333],
    TC: [21.466666666666665, -71.133333],
    TV: [-8.516666666666667, 179.216667],
    UG: [0.31666666666666665, 32.550000],
    UA: [50.43333333333333, 30.516667],
    AE: [24.466666666666665, 54.366667],
    GB: [51.5, -0.083333],
    US: [38.883333, -77.000000],
    UY: [-34.85, -56.166667],
    UZ: [41.31666666666667, 69.250000],
    VU: [-17.733333333333334, 168.316667],
    VE: [10.483333333333333, -66.866667],
    VN: [21.033333333333335, 105.850000],
    VI: [18.35, -64.933333],
    WF: [-13.95, -171.933333],
    YE: [15.35, 44.200000],
    ZM: [-15.416666666666666, 28.283333],
    ZW: [-17.816666666666666, 31.033333],
    UM: [38.883333, -77.000000],
};
