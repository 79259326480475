import {render} from "../libs/lit.dist.js";
export * from './abstract-views.js';
import {RendererInterface} from './abstract-views.js';

/**
 * Polymer.js renderer
 * Wykorzystuje bibiotekę lit-html
 * Wymaga używania tagged template: html``
 */
export class LitRenderer extends RendererInterface {
    attachHtml($el, html) {
        if ($el.length !== void 0) {
            $el = $el[0];
        }
        render(html, $el);
    }
}
