

 export const providerAdditionalFunctions = {
    POCZTEX_PUNKT: [{
        id: 'pocztex_weight_30_50',
        name: 'Przyjęcie przesyłki o masie od 30 do 50 kg (Pocztex)',
        description: 'Przyjęcie przesyłki o masie od 30 do 50 kg',
        dependsOn: ['send'],
    }],
};
