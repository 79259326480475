import {parseUrl} from '../../util/url.js';

export const baseUrl = `https://maps.polkurier.pl/`;
export const pointsDataUrl = `{baseUrl}/rest/points`;
export const mapPath = `https://tile.openstreetmap.org/{z}/{x}/{y}.png`;
export const attribution = 'Mapę dostarcza <a href="https://www.openstreetmap.org/copyright/pl" target="_blank">© OpenStreetMap</a>';
export const defaultCountry = 'PL';

export const prepareUrl = (url, params = {}) => {
    params = {
        baseUrl,
        ...parseUrl(params.baseUrl),
        ...params
    };
    params.baseUrl = String(params.baseUrl).replace(/\/$/, '');
    for (let param in params) {
        if (params.hasOwnProperty(param)) {
            url = url.replace(`{${param}}`, () => {
                return params[param] || '';
            });
        }
    }
    return url.replace(/\/$/, '');
}
