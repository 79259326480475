import {html, unsafeHTML} from '../../../../admin/script/libs/lit.dist.js';
import {cancelDomEvent} from "../../../../admin/script/util/browser.js";
import {pointIconFormatter, pointIdFormatter, pointNameFormatter} from "../providers.js";
import includes from 'lodash-es/includes.js';

export default ({ availableCouriers, visiblePoints, ...props }) => html`
    <div class="pk-points-map-body pk-flex-row pk-h-100p pk-body ${!props.showList ? 'pk-points-map--without-list' : ''} ${!props.showSearchInput ? 'pk-points-map--without-search' : ''}">
        <div class="pk-flex-col-3 pk-flex pk-flex-column pk-h-100p pk-p-3 pk-points-map-menu-container">
            ${props.showSearchInput ? html`
                <form class="pk-mb-2 pk-flex pk-search-form" @submit="${cancelDomEvent(props.performSearchDebounced)}">
                    <input 
                        type="search" 
                        class="pk-form-control pk-search-input pk-border-radius-0 pk-m-0 pk-px-2 pk-py-1" 
                        name="pk-points-map-search" 
                        .value="${props.searchQuery}"
                        @input="${props.onInputSearch}"
                    />
                    <button 
                        type="button" 
                        class="pk-bg-pk pk-bg-pk-hover pk-border-0 pk-text-white pk-font-xs pk-text-bold pk-px-4 pk-py-1 pk-cursor-pointer pk-search-submit" 
                        .disabled="${props.isSearchRequested}"
                        @click="${() => props.performSearchDebounced()}"
                    >
                        ${props.isSearchRequested ? html`<i class="pk-spinner pk-spinner-white pk-spinner-sm"></i>` : `Szukaj`}
                    </button>
                </form>
                ${availableCouriers.length > 1 ? html`
                    <div class="pk-mb-3 pk-mt-2 pk-flex-row">
                        ${availableCouriers.map(courier => html`
                            <label class="pk-flex-col-6 pk-label">
                                <input 
                                    type="checkbox" 
                                    value="${courier.id}" 
                                    class="pk-mr-2 pk-providers-checkbox"
                                    .checked="${includes(props.selectedProviders, courier.id)}" 
                                    @change="${() => props.toggleSelectedProvider(courier.id)}"
                                />
                                <span class="pk-p-0 pk-px-2 pk-shadow-2 pk-border-radius-4 pk-label-${courier.id}">${courier.name}</span>
                            </label>
                        `)}
                    </div>
                ` : ''}
                ${props.additionalFunctions?.length > 0 ? html`
                    <div class="pk-font-sm">Funkcje dodatkowe:</div>
                    <div class="pk-mb-3 pk-mt-2">
                        ${props.additionalFunctions.map(fn => html`
                            <label class="pk-label">
                                <input 
                                    type="checkbox" 
                                    value="${fn.id}" 
                                    class="pk-mr-2 pk-providers-checkbox"
                                    .checked="${props.functions.indexOf(fn.id) !== -1}" 
                                    @change="${e => props.toggleFunction(fn.id, e.target.checked)}"
                                />
                                <span class="pk-font-sm pk-text-bold">${fn.name}</span>
                            </label>
                        `)}
                    </div>
                ` : ''}
            ` : null}
            ${props.isSearchRequested ? html`<div class="pk-text-center"><i class="pk-spinner"></i></div>` : null}
            ${props.searchResultsError ? html`<div class="pk-py-5 pk-px-3 pk-text-center pk-text-danger">${props.searchResultsError}</div>` : null}
            ${props.showList ? html`
                <ul class="pk-list-unstyled pk-p-0 pk-m-0 pk-h-100p pk-overflow-auto">
                    ${visiblePoints.slice(0, 250).map(point => html`
                        <li 
                            class="pk-font-xs pk-my-2 pk-mx-0 pk-p-0 pk-bg-light-gray-hover pk-click-select-point pk-cursor-pointer" 
                            data-id="${point.id}"
                            @click="${() => props.selectPoint(point.id)}"
                            @dblclick="${() => props.confirmPoint(point.id)}"
                        >
                            <div class="pk-p-2 pk-border pk-border-2 ${props.currentPoint && props.currentPoint.id === point.id ? 'pk-border-active-pk' : 'pk-border-transparent'}">
                                <div>
                                    <span class="pk-p-0 pk-px-2 pk-shadow-2 pk-border-radius-4 pk-label-${point.provider}">${unsafeHTML(pointNameFormatter(point))}</span>
                                    ${unsafeHTML(pointIconFormatter(point))}
                                    <span class="pk-text-bold">${unsafeHTML(pointIdFormatter(point))}</span>
                                </div>
                                <div>${point.street}, ${point.city} ${point.zip}</div>
                            </div>
                        </li>
                    `)}
                </ul>
            ` : null}
        </div>
        <div class="pk-flex-col pk-map-wrapper pk-h-100p">
            <div class="pk-map-container pk-h-100p"></div>
        </div>
    </div>
`;
