const class2type = {};
const toString = class2type.toString;
const r20 = /%20/g;
const rbracket = /\[]$/;

const type = (obj) => {
    if (obj == null) {
        return obj + "";
    }
    // Support: Android < 4.0, iOS < 6 (functionish RegExp)
    return typeof obj === "object" || typeof obj === "function" ?
        class2type[toString.call(obj)] || "object" :
        typeof obj;
};

const isFunction = obj => {
    return type(obj) === "function";
};

const buildParams = (prefix, obj, add) => {
    let name, key, value;

    if (Array.isArray(obj)) {
        for (key in obj) {
            value = obj[key]
            if (rbracket.test(prefix)) {
                add(prefix, value);
            } else {

                buildParams(prefix + "[" + (typeof value === "object" ? key : "") + "]", value, add);
            }
        }
    } else if (type(obj) === 'object') {
        for (name in obj) {
            buildParams(prefix + "[" + name + "]", obj[name], add);
        }
    } else {
        add(prefix, obj);
    }
};

export const param = (obj) => {
    let prefix, key, value;
    let serialized = [];
    const add = (key, value) => {
        value = isFunction(value) ? value() : (value === null ? "" : value);
        serialized.push(encodeURIComponent(key) + "=" + encodeURIComponent(value));
    };

    if (Array.isArray(obj)) {
        for (key in obj) {
            value = obj[key];
            add(key, value);
        }
    } else {
        for (prefix in obj) {
            buildParams(prefix, obj[prefix], add);
        }
    }

    return serialized.join('&').replace(r20, '+');
};
