import {DivIcon} from "leaflet";

const defaults = {
    className: 'pk-map-marker',
    html: "<div class='pk-map-marker-pin'><i class='pk-map-marker-icon'></i></div><div class='pk-map-marker-shadow'>",
    iconSize: [30, 42],
    iconAnchor: [15, 32]
};


export const defaultMarker = new DivIcon(defaults);

export const inpostMarker = new DivIcon({
    ...defaults,
    className: 'pk-map-marker pk-map-marker-inpost pk-map-marker-inpost-parcel_locker',
});
export const inpostMarkerParcelLockerZabka = new DivIcon({
    ...defaults,
    className: 'pk-map-marker pk-map-marker-inpost pk-map-marker-inpost-parcel_locker_zabka',
});
export const inpostMarkerPop = new DivIcon({
    ...defaults,
    className: 'pk-map-marker pk-map-marker-inpost pk-map-marker-inpost-pop',
});
export const inpostMarkerPopZabka = new DivIcon({
    ...defaults,
    className: 'pk-map-marker pk-map-marker-inpost pk-map-marker-inpost-pop_zabka',
});
export const pocztexPunktMarker = new DivIcon({
    ...defaults,
    className: 'pk-map-marker pk-map-marker-pocztex_punkt',
});
export const paczkaWRuchuMarker = new DivIcon({
    ...defaults,
    className: 'pk-map-marker pk-map-marker-paczka_w_ruchu',
});
export const dpdPointMarker = new DivIcon({
    ...defaults,
    className: 'pk-map-marker pk-map-marker-dpd_point',
});
export const dhlpopMarker = new DivIcon({
    ...defaults,
    className: 'pk-map-marker pk-map-marker-dhl_pop pk-map-marker-dhl_pop-dhl',
});
export const dhlpopMarkerabc = new DivIcon({
    ...defaults,
    className: 'pk-map-marker pk-map-marker-dhl_pop pk-map-marker-dhl_pop-abc',
});
export const dhlpopMarkerinmedio = new DivIcon({
    ...defaults,
    className: 'pk-map-marker pk-map-marker-dhl_pop pk-map-marker-dhl_pop-inmedio',
});
export const dhlpopMarkerkaufland = new DivIcon({
    ...defaults,
    className: 'pk-map-marker pk-map-marker-dhl_pop pk-map-marker-dhl_pop-kaufland',
});
export const dhlpopMarkerlidl = new DivIcon({
    ...defaults,
    className: 'pk-map-marker pk-map-marker-dhl_pop pk-map-marker-dhl_pop-lidl',
});
export const dhlpopMarkershell = new DivIcon({
    ...defaults,
    className: 'pk-map-marker pk-map-marker-dhl_pop pk-map-marker-dhl_pop-shell',
});
export const dhlpopMarkerstokrotka = new DivIcon({
    ...defaults,
    className: 'pk-map-marker pk-map-marker-dhl_pop pk-map-marker-dhl_pop-stokrotka',
});
export const dhlpopMarkerzabka = new DivIcon({
    ...defaults,
    className: 'pk-map-marker pk-map-marker-dhl_pop pk-map-marker-dhl_pop-zabka',
});
export const fedexDtrMarker = new DivIcon({
    ...defaults,
    className: 'pk-map-marker pk-map-marker-fedex_dtr',
});
export const upsApPointPointMarker = new DivIcon({
    ...defaults,
    className: 'pk-map-marker pk-map-marker-ups_ap_point_point',
});

export const searchCenterMarker = new DivIcon({
    className: 'pk-map-marker--search-center',
    html: "<div></div>",
    iconSize: [32, 32],
    iconAnchor: [16, 16],
});

export const getPointMarkerIcon = point => {
    switch (point.provider) {
        case 'INPOST_PACZKOMAT':
        case 'INPOST_INTERNATIONAL':
            switch (point.type) {
                case 'pop':
                    return inpostMarkerPop;
                case 'pop_zabka':
                    return inpostMarkerPopZabka;
                case 'parcel_locker_zabka':
                    return inpostMarkerParcelLockerZabka;
            }
            return inpostMarker;

        case 'POCZTEX':
        case 'POCZTEX_PUNKT':
            return pocztexPunktMarker;

        case 'FEDEX_DTR':
            return fedexDtrMarker;

        case 'UPS_AP_POINT_POINT':
            return upsApPointPointMarker;

        case 'PACZKA_W_RUCHU':
            return paczkaWRuchuMarker;
        case 'DPD_POINT':
            return dpdPointMarker;
        case 'DHL_POINT':
            switch (point.type) {
                case 'abc':
                    return dhlpopMarkerabc;
                case 'inmedio':
                    return dhlpopMarkerinmedio;
                case 'kaufland':
                    return dhlpopMarkerkaufland;
                case 'lidl':
                    return dhlpopMarkerlidl;
                case 'shell':
                    return dhlpopMarkershell;
                case 'stokrotka':
                    return dhlpopMarkerstokrotka;
                case 'zabka':
                    return dhlpopMarkerzabka;
            }
            return dhlpopMarker;
    }
    return defaultMarker;
};
