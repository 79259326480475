// [!!] SHARED; Nie używać _, jQuery ani żadnych modułów UMD

import {EventsEmitter, triggerStateChangeEvents} from '../events-emitter.js';

export class RendererInterface {
    attachHtml($el, html) {}
}

/**
 * Standardowy renderer - zamienia cały wewnętrzny HTML na nowy
 * @deprecated
 */
export class StringRenderer extends RendererInterface {
    attachHtml($el, html) {
        console.warn('StringRenderer is deprecated');
        console.trace();
        $el.html(html);
    }
}

export const combineEventsNames = (...names) => names.join(' ');

export const prepareEventsHandlers = template => {
    const events = {};
    let eventName;
    for (let event in template) {
        for (eventName of event.replace(/\s+/g, ' ').split(' ')) {
            events[eventName] = template[event];
        }
    }
    return events;
};


export const makeDomEvents = (events, selectors, handler) => {
    const data = {};
    let event, selector;
    for (event of events) {
        for (selector of selectors) {
            data[`${event} ${selector}`] = handler;
        }
    }
    return data;
};

let nextViewId = 1;

/**
 * Podstawowa klasa widoku
 * @deprecated use AbstractComponentView
 */
export class AbstractView {

    parentModal = null;

    /**
     * @param {object} props
     */
    constructor(props = {}) {
        const {$el, renderer, ...options} = props;
        this.cid = 'view' + (nextViewId++);
        /** @deprecated */
        this.options = options;
        this.props = options;
        this.$el = $el;

        /** @var Store */
        this.store = props.store;
        this.events = this.props.events || new EventsEmitter();
        this.bindEventsEmitterEvents();
        this.attachRenderer(renderer || this.getDefaultRenderer());

        // obiekty jQuery wyszukane w widoku, na podstawie definicji z metody domElements()
        // [!!] Obiekt zostanie wypełniony dopiero po wykonaniu metody render()
        this.ui = {};

        this.bindDomEvents();
        this.bindStoreEvents();

        this.broadcastChannel = this.createBroadcastChannel();
        this.bindBroadcastChannelEvents();
    }

    /**
     * Zwraca domyślny renderer
     * @return {RendererInterface}
     */
    getDefaultRenderer() {
        return new StringRenderer();
    }

    /**
     * Tworzy kanał BroadcastChannel
     * @returns {BroadcastChannel}
     */
    createBroadcastChannel() {
        return new BroadcastChannel(this.props.broadcastChannelName || 'polkurier');
    }

    /**
     * Ustawia renderer widoku
     * @param renderer
     * @return {AbstractView}
     */
    attachRenderer(renderer) {
        if (!renderer instanceof RendererInterface) {
            throw 'renderer musi rozszerzać klasę RendererInterface';
        }
        this.htmlRenderer = renderer;
        return this;
    }

    getEventsHandlers() {
        return {};
    }

    /**
     * Zwraca eventy dla store w formacie {'nazwa:eventu:store': () => {}} np: {
     *     'change:user': () => this.render()
     * }
     * @return {{}}
     */
    storeEvents() {
        return {};
    }

    /**
     * Zwraca definicje dla zdarzeń DOM w formacie {'event .selector': () => {}} np. {
     *     'click .save-button': () => this.onClickSaveButton()
     * }
     * @return {{}}
     */
    domEvents() {
        return {};
    }

    /**
     * Zwraca definicje dla cache elementó DOM (@see getDomElement()); {
     *     nazwaWlasna: '.selector',
     * }
     * @return {{}}
     */
    domElements() {
        return {};
    }

    /**
     * Definicja eventów BroadcastChannel w formacie {'nazwa:eventu': () => {}} np: {
     *     'change:user': () => this.render()
     * }
     * @returns {Object}
     */
    broadcastChannelEvents() {
        return {};
    }

    /**
     * Dodaje nasłuch eventów DOM
     */
    bindDomEvents() {
        if (!this.$el || !this.$el.jquery) {
            return;
        }

        this._boundDomEvents = [];
        const events = this.domEvents();
        for (let config in events) {
            if (events.hasOwnProperty(config)) {
                const [event, selector] = config.replace(/\s+/g, ' ').split(' ');
                if (event) {
                    this.$el.on(event, selector || null, events[config]);
                    this._boundDomEvents.push({
                        selector,
                        event,
                        handler: events[config]
                    });
                }
            }
        }
    }

    /**
     * Usuwa nasłuch eventów DOM
     */
    unbindDomEvents() {
        if (!this._boundDomEvents) {
            return;
        }
        for (let event of this._boundDomEvents) {
            this.$el.off(event.event, event.selector, event.handler);
        }
        this._boundDomEvents = [];
    }

    /**
     * Dodaje nasłuch eventów STORE
     */
    bindStoreEvents() {
        if (this.store) {
            this.store.addEventListener(this, prepareEventsHandlers(this.storeEvents()));
        }
    }

    /**
     * Dodaje nasłuch dodatkowych eventów STORE
     * @param events
     */
    listenToStore(events) {
        if (this.store) {
            this.store.addEventListener(this, events);
        }
    }

    /**
     * Usuwa nasłuch eventów STORE
     */
    unbindStoreEvents() {
        if (this.store) {
            this.store.removeEventListener(this);
        }
    }

    /**
     * Dodaje nasłuch eventów
     */
    bindEventsEmitterEvents() {
        this.addEventsEmitterEvents(this.getEventsHandlers())
    }

    /**
     * @param events
     */
    addEventsEmitterEvents(events) {
        this.events.addEventListener(this, prepareEventsHandlers(events));
    }

    /**
     * @param eventType
     * @param handler
     */
    addEventsEmitterEvent(eventType, handler) {
        this.events.addEventListener(this, prepareEventsHandlers({[eventType]: handler}));
    }

    /**
     * Usuwa nasłuch eventów
     */
    unbindEventsEmitterEvents() {
        this.events.removeEventListener(this);
    }

    /**
     * Dodaje nasłuch eventów z BroadcastChannel
     */
    bindBroadcastChannelEvents() {
        if (!this.broadcastChannel) {
            return;
        }
        const events = this.broadcastChannelEvents();
        this.broadcastChannel.onmessage = e => {
            const {type, ...data} = e.data;
            for (let eventType in events) {
                if (events.hasOwnProperty(eventType) && eventType === type) {
                    events[eventType](data);
                }
            }
        };
    }

    /**
     * Usuwa wszystkie nasłuchy eventów
     */
    destroy() {
        this.events.emit('destroy');
        this.unbindDomEvents();
        this.unbindStoreEvents();
        this.unbindEventsEmitterEvents();
        this.broadcastChannel = null;
        this.isDestroyed = true;
        if (this.onDestroy && typeof this.onDestroy === 'function') {
            this.onDestroy();
        }
        if (this.props.onDestroy && typeof this.props.onDestroy === 'function') {
            this.props.onDestroy();
        }
    }

    /**
     * Ustawia główny kontener widoku i dodaje nasłuch eventów DOM
     * @param {jQuery} $el
     */
    setElement($el) {
        if (this.$el) {
            this.unbindDomEvents();
            this.$el = null;
        }

        if ($el) {
            // if ($el instanceof HTMLElement) {
            //     this.htmlElement = $el;
            // } else {
            // }
            this.$el = $el;
            this.bindDomEvents();
        }
        return this;
    }

    /**
     * Odłącza widok od obecnego kontenera
     * @returns {AbstractView}
     */
    detachFromElement() {
        if (this.$el) {
            this.unbindDomEvents();
            this.$el.empty();
            this.$el = null;
        }
        return this;
    }

    /**
     * Zwraca HTML do wyświetlenia
     * @return {string|object}
     */
    template(templateContext) {
        return '';
    }

    /**
     * Zwraca dane dla templatki
     * @return {*}
     */
    templateContext() {
        return {
            view: this,
            options: this.props
        };
    }

    /**
     * Zwraca element DOM z widoku
     * @deprecated
     * @param {string} name
     * @return {jQuery|undefined}
     */
    getDomElement(name) {
        return this._domElements[name];
    }

    /**
     * @param {string} selector
     * @return {HTMLElement|jquery|null}
     */
    findDomElement(selector) {
        if (!selector || !this.$el) {
            return null;
        }
        if (this.$el.jquery) {
            return this.$el.find(selector);
        }
        return this.$el.querySelector(selector);
    }

    /**
     * Wyszukuje zdefiniowane elementy DOM w widoku
     */
    initDomElements() {
        const elements = this.domElements();
        this._domElements = {};
        this.ui = this._domElements;
        for (let element in elements) {
            if (elements.hasOwnProperty(element)) {
                this._domElements[element] = this.findDomElement(elements[element]);
            }
        }
    }

    /**
     * Zwraca dane elementu do ustawienia jako aktywny po wyrenderowaniu widoku
     * @deprecated
     * @return {null|{selectionStart: null, selector: null}}
     */
    getElementToFocusAfterRender() {
        if (!this.$el || !this.$el.jquery) {
            return null;
        }

        const $el = this.$el.find(':focus');
        if (!$el.length) {
            return null;
        }

        const data = {
            selector: null,
            selectionStart: null,
            selectionDirection: null,
            selectionEnd: null,
        };

        if ($el.length) {
            if ($el[0].id) {
                data.selector = `#${$el[0].id}`;
            } else if ($el[0].name) {
                data.selector = `[name="${$el[0].name}"]`;
            }
            data.selectionStart = $el[0].selectionStart;
            data.selectionDirection = $el[0].selectionDirection;
            data.selectionEnd = $el[0].selectionEnd;
        }
        return data.selector ? data : null;
    }

    /**
     * Ustawia focus na poprzednim elemencie po przerenderowaniu widoku
     * @deprecated
     * @param focusEl
     */
    focusElementAfterRender(focusEl) {
        if (focusEl && focusEl.selector) {
            const toFocusEl = this.$el.find(focusEl.selector);
            if (toFocusEl[0]) {
                toFocusEl.focus();
                try {
                    toFocusEl[0].selectionStart = focusEl.selectionStart;
                    toFocusEl[0].selectionDirection = focusEl.selectionDirection;
                    toFocusEl[0].selectionEnd = focusEl.selectionEnd;
                } catch (ex) {}
            }
        }
    }

    /**
     * Wyświetla widok
     */
    render() {
        const firstRender = !this.isRendered;
        const focusEl = this.getElementToFocusAfterRender();
        const templateContext = this.templateContext();

        if (this.onBeforeRender && typeof this.onBeforeRender === 'function') {
            this.onBeforeRender({...templateContext, focusEl, firstRender});
        }

        this.htmlRenderer.attachHtml(this.htmlElement || this.$el, this.template(templateContext));
        this.initDomElements();
        this.isRendered = true;
        if (firstRender) {
            this.firstRendered(templateContext);
        }
        if (this.onRender && typeof this.onRender === 'function') {
            this.onRender({...templateContext, focusEl});
        }
        this.focusElementAfterRender(focusEl);
        return this;
    }

    /**
     * Metoda wywoływana tylko po pierwszym wyrenderowaniu widoku
     */
    firstRendered() {}

    /**
     * Przerenderowuje widok
     */
    rerender() {
        if (this.isRendered && !this.isDestroyed) {
            this.render();
        }
    }
}

/**
 * @deprecated use AbstractComponentView
 */
export class AbstractStatefulView extends AbstractView {

    constructor(props = {}) {
        super(props);
        this.state = {...props.state || {}};
    }

    /**
     * Ustawia wewnętrzny stan
     * @param nextState
     * @returns {AbstractView}
     */
    setState(nextState) {
        const prevState = this.state;
        this.state = {...this.state, ...nextState};
        this.onSetState();

        const state = {...this.state};
        this.onStateChange(state, prevState);
        this.triggerStateChangeEvents(state, prevState);
        return this;
    }

    /**
     * Eventy po zmianie stanu
     * @param state
     * @param prevState
     * @param root
     * @param depth
     */
    triggerStateChangeEvents(state, prevState, root = 'change', depth = 0) {
        triggerStateChangeEvents(this.events, state, prevState, root, depth);
    }

    /**
     * Eventy zmiany stanu
     * @return {object}
     */
    stateEvents() {
        return {};
    }

    /**
     * @inheritDoc
     */
    getEventsHandlers() {
        return this.stateEvents();
    }

    /**
     * @inheritDoc
     * @return {*}
     */
    templateContext() {
        return {
            ...super.templateContext(),
            state: this.state,
        };
    }

    /**
     * Akcja po ustawienie stanu
     */
    onSetState() {
        if (this.isRendered && !this.isDestroyed) {
            this.render();
        }
    }

    /**
     * Jeśli ustawiono nowy stan
     * @param state
     * @param prevState
     */
    onStateChange(state, prevState) {
    }

}
