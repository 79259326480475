
/**
 * Otwiera okienko
 * @param url
 * @returns {Window}
 */
export const openWindow = url => {
    return window.open(url,'','width=1,height=1,resizable=0,scrollbars=no,menubar=no');
};


export const createInitTooltip = props => e => jQuery(e.target).tooltip(props).tooltip('show');

export const initTooltip = createInitTooltip({
    placement: 'bottom'
});

export const initTooltipTop = createInitTooltip({
    placement: 'top'
});


export const getViewportSize = () => {
    return {
        width: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
        height: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
    }
};

/**
 * Zapewnia kopiowanie bez śmieciowych białych znaków
 * Do użycia w LIT: @copy="${onCopyHandler}"
 * @param event
 */
export const onCopyHandler = event => {
    let copiedText;
    if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
        copiedText = event.target.value.substr(event.target.selectionStart, event.target.selectionEnd - event.target.selectionStart);
    } else {
        copiedText = document.getSelection().toString().trim();
    }
    event.clipboardData.setData('text/plain', copiedText);
    event.preventDefault();
};


/**
 * Kopiuje tekst do schowka
 * @param {string} text
 * @returns {Promise<void>}
 */
export const copyToClipboard = text => {
    // Działa tylko z HTTPS!
    // @see https://stackoverflow.com/questions/51805395/navigator-clipboard-is-undefined
    // @see https://developer.mozilla.org/en-US/docs/Web/Security/Secure_Contexts
    if (navigator.clipboard) {
        return navigator.clipboard.writeText(String(text));
    }

    // Stary sposób (nie zawsze działa)
    // document.execCommand jest zdeprecjonowany
    // @see https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand
    console.warn('Copy: Fallback to old API');
    return new Promise((resolve, reject) => {
        const clipboardElement = document.createElement("textarea");
        clipboardElement.style.position = 'fixed';
        clipboardElement.style.top = '0';
        clipboardElement.style.left = '0';
        clipboardElement.style.zIndex = '-1';
        clipboardElement.style.opacity = '0';
        clipboardElement.value = String(text);
        document.body.appendChild(clipboardElement);

        setTimeout(() => {
            try {
                clipboardElement.focus();
                clipboardElement.select();
                document.execCommand('copy');
                document.body.removeChild(clipboardElement);
                resolve();
            } catch (err) {
                reject(err);
            }
        }, 10);
    });
}

/**
 * Tworzy handler blokujący propagację eventu
 * @param handler
 * @returns {function}
 */
export const cancelDomEvent = handler => e => {
    e.preventDefault();
    e.stopPropagation();
    if (typeof handler === 'function') {
        handler(e);
    }
};


/**
 * Dodaje plik CSS do sekcji <head> dokumentu
 * @param path
 * @param media
 */
export const appendCssFile = (path, media = 'all') => {
    const link = document.createElement('link')
    link.rel = 'stylesheet';
    link.href = path;
    link.media = media;
    document.getElementsByTagName('head')[0].appendChild(link);
};


export const DOWNLOAD_TYPE_TEXT = 'text/plain';
export const DOWNLOAD_TYPE_PDF = 'application/pdf';

/**
 * Wymusza pobranie pliku
 * @param {String} filename
 * @param {String} content
 * @param {String} type
 */
export const downloadFile = (filename, content, type = DOWNLOAD_TYPE_TEXT) => {
    const blob = new Blob([content], {type});
    if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
    }
    else{
        const element = document.createElement('a');
        const url = URL.createObjectURL(blob);
        element.href = url;
        element.download = filename;
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        setTimeout(() => {
            URL.revokeObjectURL(url);
        }, 10000);
    }
};

/**
 * Wymusza pobranie pliku PDF
 * @param {String} filename
 * @param {String} content
 */
export const downloadPdf = (filename, content) => downloadFile(filename, content, DOWNLOAD_TYPE_PDF);

/**
 * Wymusza pobranie pliku z obiektu Blob
 * @param {Blob|FileBlob} fileBlob
 */
export const downloadFileBlob = fileBlob => downloadFile(fileBlob.name, fileBlob.content, fileBlob.type);

/**
 * Wymusza pobranie pliku zakodowanego b base64.
 * Obsługuje DataURI. W takim przypadku argument type nie jest wymagany.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URIs
 * @param {String} filename
 * @param {String} content
 * @param {String} type
 */
export const downloadFileBase64 = (filename, content, type = DOWNLOAD_TYPE_TEXT) => {
    const separatorPosition = content.indexOf(',')
    if (content.indexOf(',') !== -1) {
        const [mediaType, encoding] = content.substring(0, separatorPosition).replace('data:', '').split(';');
        if (encoding && encoding !== 'base64') {
            throw `Invalid encoding: ${encoding}`;
        }
        content = content.substring(separatorPosition + 1);
        type = mediaType;
    }
    return downloadFile(filename, Uint8Array.from(atob(content), c => c.charCodeAt(0)), type)
};

/**
 * Zwraca czy wskazany element jest wyświetlany w oknie dialogowym
 * @param {HTMLElement|jQuery} el
 * @returns {boolean}
 */
export const isShowingInModal = el => {
    return jQuery(el).parents('.modal').length > 0;
};

/**
 * Oblicza przesunięcie pływającego elementu od góry strony
 * @returns {number}
 */
export const calculateStickyPositionOffset = () => {
    const $body = jQuery('body');
    let top = 0;
    if ($body.hasClass('header-function-fixed')) {
        const $pageHeader = jQuery('header.page-header');
        top += $pageHeader.height();

        if ($body.hasClass('nav-function-top') && $body.hasClass('nav-function-fixed')) {
            const $menu = jQuery('.page-sidebar');
            top += $menu.height();
        }
    }
    return top;
};

/**
 * Aktualizuje przesunięcie pływającego elementu od góry strony
 * @param {HTMLElement} el
 * @param {number|null} offset
 */
export const updateStickyPosition = (el, offset = 0) => {
    if (!el) {
        return;
    }
    let top = (!isShowingInModal(el) ? calculateStickyPositionOffset() : 0) + offset;
    setStickyPosition(el, top);
};

/**
 * Aktualizuje przesunięcie pływającego elementu od góry strony
 * @param {HTMLElement} el
 * @param {number} offset
 */
export const setStickyPosition = (el, offset) => {
    if (!el) {
        return;
    }
    el.style.top = Number(offset) > 0 ? `${Number(offset)}px` : 0;
};

/**
 * @param {number} offset
 * @returns {function(HTMLElement): void}
 */
export const createUpdateStickyPosition = offset => el => updateStickyPosition(el, offset);


export const listenToChangeStickyPosition = (el, offset = 0) => {
    let internalOffset = offset;
    const update = (setOffset) => {
        let domElement;
        if (el instanceof jQuery) {
            domElement = el[0];
        } else if (typeof el === 'function') {
            domElement = el();
        } else if (typeof el === 'string') {
            domElement = jQuery(el)[0];
        } else {
            domElement = el;
        }
        if (typeof setOffset === 'function' || typeof setOffset === 'number') {
            internalOffset = setOffset;
        }
        if (el) {
            updateStickyPosition(domElement, typeof internalOffset === 'function' ? internalOffset() : internalOffset);
        }
    };
    window.addEventListener('resize', update);
    document.addEventListener('change:theme:settings', update);
    return {
        el,
        offset,
        update,
        stopListening: () => {
            window.removeEventListener('resize', update);
            document.removeEventListener('change:theme:settings', update);
        },
    };
};

/**
 * Znajduje maksymalny zIndex w dokumencie
 * @param selector
 * @returns {number}
 */
export const findMaxZIndex = (selector = '*') => {
    const elements = document.querySelectorAll(selector);
    let highest = 0;
    let zIndex;
    for (const element of elements) {
        zIndex = parseInt(getComputedStyle(element, null).zIndex, 10);
        if (zIndex > highest) {
            highest = zIndex;
        }
    }
    return highest;
}

/**
 * Scrolluje zawartość elementu do samego dołu
 * @param {HTMLElement} el
 */
export const scrollToBottom = el => {
    if (el) {
        setTimeout(() => {
            el.scrollTop = el.scrollHeight - el.clientTop
        }, 0);
    }
};

export const getFistMatchingParent = (el, selector) => {
    let current = el.parentElement;
    while (current) {
        if (current.matches(selector)) {
            return current;
        }
        current = current.parentElement;
    }
    return false;
}

export const isOrHasParent = (el, selector) => {
    let current = el;
    while (current) {
        if (current.matches(selector)) {
            return true;
        }
        current = current.parentElement;
    }
    return false;
}
