import {html, unsafeHTML} from "../../../../admin/script/libs/lit.dist.js";
import {pointIconFormatter, pointIdFormatter, pointNameFormatter} from '../providers.js';
import pointStatusDescription from '../point-status-description.js';
import mobileIcon from '../../images/icons/mobile-android.svg';
import clockIcon from '../../images/icons/clock.svg';

export default ({point, state, ...props}) => html`
    <div class="pk-mb-3">
        <span class="pk-p-2 pk-px-4 pk-my-1 pk-shadow-2 pk-border-radius-4 pk-d-inline-block pk-label-${point.provider}">${unsafeHTML(pointNameFormatter(point))}</span>
        ${unsafeHTML(pointIconFormatter(point))}
        <strong class="pk-text-bold"> - ${unsafeHTML(pointIdFormatter(point))}</strong>
    </div>
    <div>${point.street}, ${point.city} ${point.zip}</div>
    ${point.description ? html`<div class="pk-mb-4 pk-mt-2">${point.description}</div>` : ''}
    <div class="pk-mt-3 pk-font-lg">
        Płatność za pobraniem: <strong class="pk-text-bold">${point.cod ? 'Tak' : 'Nie'}</strong>
    </div>
    ${Array.isArray(point.functions) && Array.isArray(props.additionalFunctions) ? props.additionalFunctions.map(fn => html`
        <div class="pk-mt-2 pk-font-lg pk-text-nowrap">
            ${fn.description}:
            <strong class="pk-text-bold">${point.functions.indexOf(fn.id) !== -1 ? 'Tak' : 'Nie'}</strong>
        </div>
    `) : null}
    ${point.openingHours ? html`
        <div class="pk-mt-4 pk-flex">
            <img src="${clockIcon}" alt="" style="width: 1.5rem; height: 1.5rem" />
            <div class="pk-ml-3">
                <div class="pk-mb-1">
                    Godziny otwarcia:
                </div>
                <div>${unsafeHTML(point.openingHours.replace(/\n/gim, '<br>'))}</div>
            </div>
        </div>
    ` : ''}
    ${point.requireApp ? html`
        <div class="pk-mt-4 pk-flex">
            <img src="${mobileIcon}" alt="" style="width: 2rem; height: 2rem" />
            <div class="pk-ml-2">
                ${point.requireAppMessage ? html`
                    <div class="pk-mb-2">${point.requireAppMessage}</div>
                ` : null}
                <div class="pk-text-danger">
                    <strong class="pk-text-bold">
                        Uwaga: Aby skorzystać z tego punktu wymagana jest aplikacja przewoźnika ${unsafeHTML(pointNameFormatter(point))}
                    </strong>
                </div>
            </div>
        </div>
    ` : null}
    ${state.showSelectButton !== false ? html`
        <div class="pk-mt-3 pk-text-center">
            ${point.available ? html`
                <button 
                    type="button" 
                    data-id="${point.id}" 
                    data-provider="${point.provider}" 
                    class="pk-click-confirm-point pk-bg-pk pk-bg-pk-hover pk-p-3 pk-border-0 pk-text-bold pk-shadow-1 pk-px-4 pk-text-white pk-border-radius-2"
                    @click="${() => props.confirmPoint(point.id)}"
                >Wybierz punkt</button>
            ` : `
                <div class="text-danger pk-p-4">
                    <span class="pk-text-bold">Wybrany punkt jest w tej chwili niedostępny</span>
                    ${pointStatusDescription[point.status] ? html`<br>${pointStatusDescription[point.status]}` : ''}
                </div>
            `}
        </div>
    ` : ''}
`;
